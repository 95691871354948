<template>
  <div v-attributes="schema.attributes" class="code-field">
    <button class="button maximize-button is-primary" @click="maximized = true">
      <i class="mdi mdi-fullscreen" />
    </button>
    <codemirror
      v-model="valueAsString"
      :options="editorOptions"
      class="editor field-editor small"
    />
    <div v-if="maximized">
      <portal to="portalComplexField">
        <div class="complex-field-editor code-field">
          <button class="button minimize-button is-primary" @click="maximized = false">
            <i class="mdi mdi-fullscreen-exit" />
          </button>
          <div class="content">
            <codemirror
              v-model="value"
              :options="editorOptions"
              class="editor field-editor"
            />
          </div>
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

import 'codemirror/lib/codemirror.css';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/vue/vue';
import 'codemirror/theme/idea.css';
import JSON5 from 'json5';

import codemirror from '@/components/ui/Codemirror';

export default {
  components: {
    codemirror,
  },
  mixins: [abstractField],
  data() {
    return {
      maximized: false,
      valueAsString: JSON5.stringify(this.value, null, 2),
      editorOptions: {
        tabSize: 2,
        lineWrapping: true,
        mode: { name: 'javascript' },
        theme: 'idea',
        lineNumbers: true,
        line: true,
        keyMap: 'sublime',
        autoCloseBrackets: true,
        readOnly: this.schema.disabled,
      },
    };
  },
  watch: {
    valueAsString(v) {
      try {
        this.value = JSON5.parse(v);
      } catch (e) {

      }
    },
  },
};
</script>
<style scoped>
.code-field {
  position: relative;
}
.editor {
  width: 100%;
  max-width: 940px;
  border-radius: 4px;
}
.maximize-button {
  position: absolute;
  right: 12px;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.2s;
}
.code-field:hover .maximize-button {
  opacity: 1;
}
.code-field .small {
  font-size: 70%;
}
</style>
<style>
.field-editor .CodeMirror {
  height: 140px;
}
.complex-field-editor .CodeMirror {
  height: 460px;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}
.field-editor .CodeMirror-scroll {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  border-radius: 4px;
}
.complex-field-editor.code-field .minimize-button {
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 100;
}
</style>
