<template>
  <div v-attributes="schema.attributes">
    <input class="input" :value="prettyDate" type="text" disabled>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
  computed: {
    prettyDate() {
      return new Date(this.value).toLocaleString();
    },
  },
};
</script>
