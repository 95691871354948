<template>
  <div v-attributes="schema.attributes">
    <vue-form-generator
      :schema="validatorSchema"
      :model="value"
      class="field-property-validator"
      tag="div"
    />
    <vue-form-generator
      :schema="mainSchemaOptions"
      :model="$parent.$parent.model"
      class="field-property-validator"
      tag="div"
    />
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
  data() {
    if (!this.value) {
      this.value = {};
    }
    return {
      validatorSchema: {
        fields: [{
          type: 'select',
          values: ['string', 'regex', 'email'],
          model: 'type',
        }, {
          type: 'text',
          label: 'Regex',
          model: 'regex',
          visible: (model) => model && model.type === 'regex',
        }],
      },
      mainSchemaOptions: {
        fields: [{
          type: 'number',
          label: 'Longueur minimum',
          model: 'min',
          visible: (model) => model && model.validatorFunction.type === 'string',
        }],
      },
    };
  },
};
</script>
<style scoped>
.field-property-validator >>> .form-group {
  display: block !important;
}
</style>
