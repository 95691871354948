<template>
  <div v-attributes="schema.attributes" class="field-icon-boxes">
    <a :class="`box ${value === v.value ? 'selected' : ''}`" v-for="v in schema.values" :key="v.value" @click="value = v.value">
      <i :class="`mdi mdi-${v.icon} mdi-48px`" />
    </a>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
};
</script>
<style scoped>
.field-icon-boxes {
  display: flex;
}
.box {
  width: 96px;
  height: 96px;
  padding: 24px;
  padding-top: 28px;
  padding-bottom: 20px;
  border:  1px solid whitesmoke;
  border-radius: 0;
  color: #aaa;
  transition: color 0.2s linear, border 0.2s linear;
}
.box.selected {
  border:  1px solid var(--primary-color);
  color: var(--primary-color);
}
</style>
