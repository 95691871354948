<template>
  <b-input v-attributes="schema.attributes" :value="value" class="field-number" type="number" :disabled="schema.disabled" @input="input" />
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
  methods: {
    input($event) {
      this.value = parseInt($event);
    },
  },
};
</script>
