<template>
  <div v-attributes="schema.attributes">
    <b-message type="is-danger" has-icon>
      Attention: le document est en train d'être édité par un autre utilisateur (bfrancois)!
    </b-message>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
};
</script>
<style scoped>
.link {
  text-decoration: underline;
}
</style>
