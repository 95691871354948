<template>
  <div v-attributes="schema.attributes">
    <input v-model="condition.type" class="input" type="text">
    <input v-model="condition.params" class="input" type="text">
    <button class="button delete-button" @click="$emit('delete')">
      <i class="mdi mdi-delete" />
    </button>
    <span v-if="value">
      <!--<b-dropdown :value="value[0]" aria-role="list" class="rule-dropdown" @input="$emit('input', [ $event, value[1] ])">
        <button slot="trigger" class="button" type="button">
          <template>
            <span>{{ value[0] }}</span>
          </template>
          <i class="mdi mdi-menu-down" />
        </button>
        <b-dropdown-item value="owner" aria-role="listitem">
          <div class="media">
            <div class="media-content">
              {{ $t('owner') }}
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item value="role" aria-role="listitem">
          <div class="media">
            <div class="media-content">
              {{ $t('role') }}
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <div class="argument-input">
        <b-input v-if="value[0] === 'role'" v-model="value[1]" type="text" />
      </div>
      <button class="button delete-button" @click="$emit('delete')">
        <i class="mdi mdi-delete" />
      </button>
    </span>
    -->
    </span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valueSplitted: [],
    };
  },
  /*
  watch: {
    valueSplitted: {
      handler (v) {
        this.$emit('input', v.join(':'));
      },
      deep: true
    }
  },
  mounted () {
    if (this.value) {
      return this.value.split(':');
    }
  }
  */
};
</script>
