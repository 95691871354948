<template>
  <div v-attributes="schema.attributes">
  <b-autocomplete
    v-if="schemaName !== undefined"
    :value="selectedField ? selectedField.model : value"
    open-on-focus
    :disabled="schema.disabled"
    icon="folder"
    :data="fields"
    @select="option => selectedField = option"
  >
    <template slot-scope="props">
      <div class="media-content">
        <i :class="`mdi mdi-${props.option.schema? props.option.schema.icon : ''}`" />
        {{ props.option.label }}
      </div>
    </template>
  </b-autocomplete>
  <b-input v-else v-model="value" />
</div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import { mapState } from 'vuex';
import Autocomplete from '@/components/ui/Autocomplete';

import vfgfields from '@/core/vfgfields';

export default {
  components: {
    Autocomplete,
  },
  mixins: [abstractField],
  computed: {
    ...mapState({
      schemas: (state) => state.abstractElements.objects.schemas.objects,
    }),
    schemaName () {
      if (this.schema && this.schema.schemaName) {
        if (typeof this.schema.schemaName === 'string') {
          return this.schema.schemaName;
        } else if (typeof this.schema.schemaName === 'function') {
          return this.schema.schemaName(this.$parent.$parent.model);
        }
      }
      if (this.$parent.model) {
        return this.$parent.model.schema;
      }
    },
    fields() {
      const res = [];
      const schema = this.schemas.filter((s) => s.name === this.schemaName)[0];
      const categoriesObjects = {};
      for (const field of schema.fields) {
        res.push({
          ...field,
          schema: vfgfields.fields.filter((f) => f.originalLabel === field.type)[0],
        });
      }
      return res;
    },
    selectedField: {
      get() {
        return this.fields.filter((f) => f.model === this.value)[0];
      },
      set(v) {
        this.value = v.model;
      },
    },
  },
};
</script>
<style scoped>
.field-schema-property-selector {
  width: 100%;
}
</style>
