<template>
  <div v-attributes="schema.attributes" class="field-function">
    <button class="button maximize-button is-primary" @click="maximized = true">
      <i class="mdi mdi-fullscreen" />
    </button>
    <pre>function ({{ schema.params ? schema.params.map(p => p.name).join(', ') : ''}}) {</pre>
    <codemirror
      v-model="value"
      :options="editorOptions"
      class="editor field-editor small"
    />
    <pre>}</pre>
    <div v-if="maximized">
      <portal to="portalComplexField">
        <div class="complex-field-editor code-field">
          <button class="button minimize-button is-primary" @click="maximized = false">
            <i class="mdi mdi-fullscreen-exit" />
          </button>
          <div class="content">
            <pre>function ({{ schema.params ? schema.params.map(p => p.name).join(', ') : ''}}) {</pre>
            <codemirror
              v-model="value"
              :options="editorOptions"
              class="editor field-editor"
            />
            <pre>}</pre>
          </div>
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

import 'codemirror/lib/codemirror.css';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/vue/vue';
import 'codemirror/theme/idea.css';

import codemirror from '@/components/ui/Codemirror';

export default {
  components: {
    codemirror,
  },
  mixins: [abstractField],
  data() {
    return {
      maximized: false,
      editorOptions: {
        tabSize: 2,
        lineWrapping: true,
        mode: { name: 'js' },
        theme: 'idea',
        lineNumbers: true,
        line: true,
        keyMap: 'sublime',
        readOnly: this.schema.disabled,
        autoCloseBrackets: true,
      },
    };
  },
};
</script>
<style scoped>
.field-function {
  position: relative;
}
.editor {
  width: 100%;
  max-width: 940px;
  border-radius: 4px;
}
.maximize-button {
  position: absolute;
  right: 12px;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.2s;
}
.field-function:hover .maximize-button {
  opacity: 1;
}
.field-function .small {
  font-size: 70%;
}
</style>
<style>
.field-function .CodeMirror {
  height: 80px !important;
}
.complex-field-editor .CodeMirror {
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}
.field-function .CodeMirror-scroll {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  border-radius: 4px;
}
.complex-field-editor.field-function {
  height: calc(85vh - 40px);
}
.complex-field-editor.field-function .minimize-button {
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 100;
}
</style>
