<template>
  <div v-attributes="schema.attributes" class="field-color">
    <b-dropdown v-attributes="'input'" aria-role="list">
      <button slot="trigger" :disabled="schema.disabled" style="`background: ${value || none}`" class="button">
        <span v-if="value" class="color-label">
          {{ value }}
        </span>
      </button>
      <swatches-picker v-model="color" />
    </b-dropdown>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import { Swatches } from 'vue-color';

export default {
  components: {
    'swatches-picker': Swatches,
  },
  mixins: [abstractField],
  data() {
    return {
      color: '',
    };
  },
  watch: {
    color(v) {
      this.value = v.hex;
    },
  },
};
</script>
<style scoped>
.color-label {
  text-shadow: 1px 1px 0px rgba(255,255,255,1), 1px -1px 0px rgba(255,255,255,1), -1px 1px 0px rgba(255,255,255,1), -1px -1px 0px rgba(255,255,255,1);
  font-weight: bold;
  color: black !important;
}
</style>
