<template>
  <div v-attributes="schema.attributes" class="field-document">
    <div class="card">
      <a
        class="card-header"
        @click="openEditor"
      >
        <span
          v-if="value"
          class="card-header-title"
        >
          <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document'}`" />
          &nbsp;
          <span v-if="typeof value === 'string' || typeof value === 'number'">
            {{ value }}
          </span>
          <span v-else>
            {{ value[labelField] }}
          </span>
        </span>
        <a
          v-else
          class="card-header-title"
          @click="openEditor"
        >
          ({{ schema.emptyLabel || i18n.nothing }})
        </a>
        <span v-if="schema.canCreateNewDocument" class="card-header-icon prevent-click" style="padding: 0;">
          <b-tooltip label="create">
            <b-dropdown
              ref="new-document-dropdown"
              position="is-bottom-left"
              class="new-document-dropdown"
              @active-change="newItemFieldValue = ''"
            >
              <a slot="trigger" class="button is-rounded is-primary">
                <i class="mdi mdi-plus" />
              </a>
              <div class="field has-addons" style="font-size: 14px;">
                <p class="control">
                  <input v-model="newItemFieldValue" class="input">
            </p>
                <p class="control">
                  <a class="button is-primary" style="float: right" @click="createNewDocument">
                    <i class="mdi mdi-check" />
                  </a>
                </p>
              </div>
            </b-dropdown>
          </b-tooltip>
        </span>
        <a v-if="value" class="card-header-icon no-print" @click.stop="value = undefined">
          <i class="mdi mdi-close" />
        </a>
        <a class="card-header-icon no-print">
          <i class="mdi mdi-magnify" />
          <i class="mdi mdi-menu-right" />
        </a>
      </a>
    </div>
    <div v-if="editorOpened">
      <portal to="portalComplexField">
        <div class="complex-field-editor document-field">
          <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
          <a class="back-button" @click.stop="editorOpened = false">
            <i class="mdi mdi-arrow-left" />
            {{ i18n.back }}
          </a>
          <div class="field-header">
            <h3 v-if="schema.titleLabel && schema.titleLabel !== ''" class="title is-3">
              {{ schema.titleLabel }}
            </h3>
            <h3 v-else-if="schema.titleLabel !== ''" class="title is-3">
              {{ i18n.edit_property }} {{ schema.label ? `"${schema.label}"` : '' }}
            </h3>
            <h4 v-if="schema.subtitleLabel && schema.subtitleLabel !== ''" class="subtitle is-4">
              {{ schema.subtitleLabel }}
            </h4>
            <h4 v-else-if="schema.subtitleLabel !== ''">
              {{ i18n.associate_a_document_from_the }} <span class="tag">{{ collection }}</span> {{ i18n.collection }}
            </h4>
          </div>
          <div class="content">
            <b-message v-if="error" type="is-danger" has-icon>
              {{ error }}
            </b-message>
            <div v-if="search" class="search-container">
              <SearchField v-model="searchText" class="search" />
              <b-dropdown v-if="filters" v-model="activeFilter" aria-role="list" position="is-bottom-left">
                <button slot="trigger" class="button" type="button">
                  <span><i class="mdi mdi-filter" /> {{ activeFilter ? activeFilter.label : 'all' }}</span>
                  <i class="mdi mdi-menu-down" />
                </button>
                <b-dropdown-item v-for="f in filters" :key="f.label" :value="f" aria-role="listitem">
                  <div class="media">
                    <div class="media-content">
                      {{ f.label }}
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <b-tabs v-if="allGroups && allGroups.length && groupedResults" type="is-boxed" vertical>
              <b-tab-item
                v-if="$parent.schema && $parent.schema.type === 'array'"
                :label="i18n.selected + ` (${ $parent.value ? $parent.value.length : 0 })`"
              >
                <table v-if="relationSchema && relationSchema.fields && relationSchema.fields.length" class="table is-striped is-hoverable is-fullwidth results">
                  <thead>
                    <tr>
                      <th style="width: 40px" />
                      <th v-for="p in relationSchema.fields" :key="p.model">
                        {{ p.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="r in $parent.value" :key="r._id" :class="`${isSelected(r) ? 'selected': ''}`" @click.stop="selectDocument(r)">
                      <td>
                        <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document'}`" />
                      </td>
                      <td v-for="p in relationSchema.fields" :key="p.model">
                        {{ r[p.model] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="table is-bordered is-striped is-hoverable is-fullwidth results">
                  <div v-for="r in $parent.value" :key="r._id" :class="`card ${isSelected(r) ? 'selected': ''}`" @click.stop="selectDocument(r)">
                    <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document'}`" />
                    {{ r[labelField] }}
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item v-for="g in allGroups" :key="g">
                <template #header>
                  {{g}}
                  <a class="tag field-document-click-on-tab-button" v-if="schema.clickOnTabFunction" @click="clickOnTab(g, groupedResults[g])" style="margin-left: auto; border: 0;">
                    <i class="mdi mdi-plus" />
                  </a>
                </template>
                <table v-if="relationSchema && relationSchema.fields && relationSchema.fields.length" class="table is-striped is-hoverable is-fullwidth results">
                  <thead>
                    <tr>
                      <th style="width: 40px" />
                      <th v-for="p in relationSchema.fields" :key="p.model">
                        {{ p.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="r in groupedResults[g]" :key="r._id" :class="`${isSelected(r) ? 'selected': ''}`" @click.stop="selectDocument(r)">
                      <td>
                        <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document'}`" />
                      </td>
                      <td v-for="p in relationSchema.fields" :key="p.model">
                        {{ r[p.model] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="table is-bordered is-striped is-hoverable is-fullwidth results">
                  <div v-for="r in groupedResults[g]" :key="r._id" :class="`card ${isSelected(r) ? 'selected': ''}`" @click.stop="selectDocument(r)">
                    <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document'}`" />
                    {{ r[labelField] }}
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
            <div v-else>
              <table v-if="relationSchema && relationSchema.fields && relationSchema.fields.length" class="table is-striped is-hoverable is-fullwidth results">
                <thead>
                  <tr>
                    <th style="width: 40px" />
                    <th v-for="p in relationSchema.fields" :key="p.model">
                      {{ p.label }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in results" :key="r._id" :class="`${isSelected(r) ? 'selected': ''}`" @click.stop="selectDocument(r)">
                    <td>
                      <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document'}`" />
                    </td>
                    <td v-for="p in relationSchema.fields" :key="p.model">
                      {{ r[p.model] }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="table is-bordered is-striped is-hoverable is-fullwidth results">
                <div v-for="r in results" :key="r._id" :class="`card ${isSelected(r) ? 'selected': ''}`" @click.stop="selectDocument(r)">
                  <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document'}`" />
                  {{ r[labelField] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import Handlebars from 'handlebars';
import Fuse from 'fuse.js';
import { debounce } from 'vue-debounce';
import sift from 'sift';
import json5 from 'json5';
import SearchField from '@/components/ui/SearchField';
import deepClone from '@/core/utils/deepClone';
import i18n from 'i18n/components/vfg/FieldDocument.json';

export default {
  name: 'FieldDocument',
  components: {
    SearchField,
  },
  mixins: [abstractField],
  data() {
    const collection = this.schema.relation ? this.schema.relation.collection : undefined;

    return {
      i18n,
      searchText: '',
      labelField: this.schema.labelField || 'title',
      onlyFields: this.schema.onlyFields,
      search: this.schema.search,
      onlyFieldAsString: this.schema.onlyFieldAsString,
      groupKey: this.schema.groupKey,
      results: [],
      allGroups: [],
      filters: this.schema.filters,
      activeFilter: '',
      newItemFieldValue: '',
      collection,
      groupedResults: undefined,
      bucket: undefined,
      query: undefined,
      error: undefined,
      editorOpened: false,
      debouncedSearch: debounce(() => {
        if (!this.activeFilter || Object.keys(this.activeFilter).length === 0) {
          this.results = deepClone(this.allResults);
        } else {
          let query = {};
          if (typeof this.activeFilter.query === 'object') {
            query = this.activeFilter.query;
          } else if (typeof this.activeFilter.query === 'string') {
            query = json5.parse(this.activeFilter.query);
          }
          this.results = deepClone(this.allResults);
          this.results = this.results.filter(sift(query));
        }

        const options = {
          shouldSort: true,
          threshold: 0.1,
          limit: 50,
          tokenize: !!this.fuzzySearch,
          keys: this.schema.onlyFields || [],
        };
        if (this.searchText && this.searchText !== '') {
          const fuse = new Fuse([...this.results], options);
          this.results = fuse.search(this.searchText);
        } else {
          this.results = this.allResults;
        }
      }, 500),
    };
  },
  computed: {
    allResults: {
      get() {
        if (!this.collection || !window.$store.state.abstractElements.objects[this.bucket] || !window.$store.state.abstractElements.objects[this.bucket].objects) {
          return [];
        }
        return window.$store.state.abstractElements.objects[this.bucket].objects;
      },
    },
    relationSchema() {
      const schemas = window.$store.state.abstractElements.objects.schemas.objects;
      if (schemas) {
        for (let i = 0; i < schemas.length; i++) {
          if (schemas[i].name === this.collection || schemas[i].name === this.schema.schemaName) {
            const schema = { ...schemas[i] };
            if (this.onlyFields) {
              schema.fields = schema.fields.filter((f) => this.onlyFields.includes(f.model));
            }
            return schema;
          }
        }
      }
      return { fields: [] };
    },
    isLoading () {
      if (this.bucket && window.$store.state.abstractElements.objects[this.bucket]) {
        return window.$store.state.abstractElements.objects[this.bucket].isLoading;
      } else {
        return false;
      }
    },
  },
  watch: {
    searchText: 'doFilter',
    activeFilter: 'doFilter',
    results: {
      handler(results) {
        const { groupKey } = this;
        if (groupKey) {
          const groupedResults = {};
          for (let i = 0; i < results.length; i++) {
            if (groupedResults[results[i][groupKey]] === undefined) {
              groupedResults[results[i][groupKey]] = [];
            }
            groupedResults[results[i][groupKey]].push(results[i]);
          }
          this.groupedResults = groupedResults;
        }
      },
      deep: true,
    },
    allResults: {
      handler(v) {
        const results = v;
        this.results = results;
        if (this.groupKey) {
          this.extractAllGroups();
          const groupedResults = {};
          const { groupKey } = this;
          for (let i = 0; i < results.length; i++) {
            if (groupedResults[results[i][groupKey]] === undefined) {
              groupedResults[results[i][groupKey]] = [];
            }
            groupedResults[results[i][groupKey]].push(results[i]);
          }
          this.groupedResults = groupedResults;
        }

        this.doFilter();
      },
      deep: true,
    },
    query: {
      handler(q) {
        this.bucket = this.schema.bucket || `vfg-field-document-${this.collection}-${q ? JSON.stringify(q) : 'all'}`;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.schema.query) {
      try {
        if (typeof this.schema.query === 'string') {
          const template = Handlebars.compile(this.schema.query);
          this.query = template({
            model: this.$parent.model,
          });
        } else {
          this.query = JSON.stringify(this.schema.query);
        }
      } catch (e) {
        console.error('malformed query JSON: ', e, this.schema.query);
      }
    } else {
      this.query = undefined;
    }
    this.bucket = this.schema.bucket || `vfg-field-document-${this.collection}-${this.query ? JSON.stringify(this.query) : 'all'}`;

    this.queryDocuments();
  },
  methods: {
    async createNewDocument() {
      const newDoc =  {
        ...this.schema.newDocumentDefaultValue,
        [this.labelField]: this.newItemFieldValue,
      };
      if (this.schema.newDocumentFieldInputAssignKeys) {
        for (let f of this.schema.newDocumentFieldInputAssignKeys) {
          newDoc[f] = this.newItemFieldValue;
        }
      }
      const res = await window.$store.dispatch('abstractElements/createObject', {
        collection: this.schema.relation.collection,
        destinationBucket: this.bucket,
        object: newDoc,
      });
      const newSchema = res.data.document;
      this.selectDocument(newSchema);
      this.newItemFieldValue = '';
      this.$refs['new-document-dropdown'].toggle();
    },
    extractAllGroups() {
      const { groupKey } = this;
      const res = [];
      if (this.groupKey) {
        for (let i = 0; i < this.allResults.length; i++) {
          if (!res.includes(this.allResults[i][groupKey])) {
            res.push(this.allResults[i][groupKey]);
          }
        }
      }
      this.allGroups = res;
    },
    isSelected(record) {
      if (this.value && record._id === this.value._id) {
        return true;
      }
      if (this.$parent.schema && this.$parent.schema.type === 'array' && this.$parent.value && this.$parent.value.filter((recordSelected) => {
        if (recordSelected._id === record._id) {
          return true;
        }
        if (this.schema.onlyFieldAsString && record[this.schema.onlyFieldAsString] === recordSelected) {
          return true;
        }
        return false;
      }).length >= 1) {
        return true;
      }
    },
    queryDocuments() {
      const { collection } = this;
      const destinationBucket = this.bucket;
      const bucket = window.$store.state.abstractElements.objects[`vfg-field-document-${collection}-all`];
      if (!bucket || bucket.isLoading !== true) {
        window.$store.dispatch('abstractElements/fetchObjects', { collection, destinationBucket, query: this.query });
      }
      // const payload = await this.$axios.$get(`/${this.collection}?query=${this.query || '{}'}`);
    },
    selectDocument(document) {
      document = { ...document };
      if (this.onlyFields) {
        const docKeys = Object.keys(document);
        for (let i = 0; i < docKeys.length; i++) {
          if (!this.onlyFields.includes(docKeys[i]) && docKeys[i] !== '_id') {
            delete document[docKeys[i]];
          }
        }
      }
      if (this.onlyFieldAsString) {
        this.value = document[this.onlyFieldAsString];
      } else {
        delete document._metadatas;
        this.value = document;
      }
      this.editorOpened = false;
    },
    openEditor(e) {
      if (e.target.closest('.prevent-click') === null) {
        this.editorOpened = true;
      }
    },
    doFilter() {
      try {
        this.debouncedSearch();
      } catch (e) {
        console.error(e);
      }
    },
    clickOnTab(g, results) {
      const res = this.schema.clickOnTabFunction(g, results, this.value);
      if (res === true) {
        this.editorOpened = false;
      }
    },
  },
};
</script>
<style scoped>
.back-button {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}
.field-document {
  width: 100%;
}

.results {
  margin-top: 20px;
  padding: 10px;
  overflow: auto;
}
div.results {
  display: grid;
  grid-column-gap: 10px;
}
.results .card {
  padding: 8px;
  cursor: pointer;
}
.results tr {
  cursor: pointer;
}
.results .card:hover {
  background: #f8f8f8;
}
.results .selected{
  border: 4px solid #7957d5;
}
.results .selected td:first-child{
  background: #7957d5;
  color: white;
}
.results .card.selected {
  background: #7957d5;
  color: white;
  border: 0;
}
.results .selected td{
  padding-top: 4px;
  padding-bottom: 4px;
}
.card .card-header-icon {
  transition: margin-top 0.2s, margin-bottom 0.2s, font-size 0.2s;
}

.card {
  transition: background 0.2s, box-shadow 0.2s,;
  background: #f9f9f9;
  border: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  overflow: visible;
}

.card:hover {
  background: #CCCCCC22;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.1)
}

.card:hover .card-header-icon {
  margin-top: -6px;
  margin-bottom: -7px;
  font-size: 24px;
}
</style>
<style>
.complex-field-editor.document-field {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.complex-field-editor.document-field .field-header {
  margin-bottom: 20px;
}
.complex-field-editor.document-field .content {
  width: calc(100% - 2px);
  flex-grow: 1;
}
.complex-field-editor.document-field .content .b-tabs {
  height: 100%;
}
.complex-field-editor.document-field h3, .complex-field-editor.document-field h4 {
  margin: 0;
  margin-left: 20px;
}
.complex-field-editor.document-field .tab-content .tab-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.complex-field-editor.document-field .tab-content {
  padding: 0;
}
.complex-field-editor.document-field .modal-card-body {
  overflow: hidden;
  padding: 0;
  height: 85vh;
}
.complex-field-editor.document-field .search-container {
  padding: 20px;
  padding-bottom: 10px;
  background: whitesmoke;
  border-bottom: 1px solid #dbdbdb;
  display: inline-flex;
  width: 100%;
}
.complex-field-editor.document-field .search-container .search {
  flex-grow: 1;
}
</style>
<style>
  .new-document-dropdown .dropdown-content {
    padding: 0 !important;
  }
</style>
