<template>
  <b-dropdown ref="dropdown" v-attributes="schema.attributes" aria-role="list" class="field-custom-select full-width">
    <button slot="trigger" class="button full-width">
      <span v-if="value">
        <i :class="`mdi mdi-${selectedValueIcon || 'tag'}`" />&nbsp;
        <span v-if="!schema.displayOnlyIcon">
          {{ value }}
        </span>
      </span>
      <span v-else>Nothing</span>
      <i class="mdi mdi-menu-down" />
    </button>
    <b-tabs :vertical="true" type="is-boxed" class="field-custom-select-tabs">
      <b-tab-item v-for="(cat, catName) in valuesByCategory" :key="catName" :label="catName">
        <a
          v-for="v in cat"
          :key="v[schema.valueKey]"
          class="dropdown-item"
          aria-role="listitem"
          @click="select(v)"
        >
          <i :class="`mdi mdi-${v[schema.iconKey] || 'tag'}`" />&nbsp;
          <b>{{ v[schema.labelKey] }}</b>
          <div v-if="v[schema.descriptionKey]">
            {{ v[schema.descriptionKey] }}
          </div>
        </a>
      </b-tab-item>
    </b-tabs>
  </b-dropdown>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
  data() {
    const valuesByCategory = {};
    const { categoryField } = this.schema;
    for (let i = 0; i < this.schema.values.length; i++) {
      const currentValue = this.schema.values[i];
      if (valuesByCategory[currentValue[categoryField]] === undefined) {
        valuesByCategory[currentValue[categoryField]] = [];
      }
      valuesByCategory[currentValue[categoryField]].push(currentValue);
    }

    return {
      valuesByCategory,
    };
  },
  computed: {
    selectedValueIcon() {
      if (this.value) {
        for (let i = 0; i < this.schema.values.length; i++) {
          const currentValue = this.schema.values[i];
          if (currentValue[this.schema.valueKey] === this.value) {
            return currentValue[this.schema.iconKey];
          }
        }
      }
      return undefined;
    },
  },
  methods: {
    select(v) {
      this.value = v[this.schema.valueKey];
      console.log('customselect value', v[this.schema.valueKey], v);
      this.$refs.dropdown.toggle();
    },
  },
};
</script>
<style scoped>
  .full-width {
    width: 100%;
  }
  button {
    text-align: left !important;
  }
  button:focus {
    color: black !important;
  }
  button .icon {
    float: right !important;
  }
</style>
<style>
  .field-custom-select .dropdown-trigger, .field-custom-select .dropdown-menu {
    width: 100%;
  }
  .field-custom-select .b-tabs {
    display: flex;
    align-items: flex-start;
  }
  .field-custom-select .dropdown-item {
    display: block;
    border-bottom: 1px solid #dbdbdb;
  }
  .field-custom-select .dropdown-item > div {
    white-space: normal;
  }
  .field-custom-select .tab-content {
    max-height: 300px;
    overflow-y: auto;
  }
  .field-custom-select .dropdown-item > i {
    float: left;
  }
  .field-custom-select .dropdown-content {
    padding: 0;
    padding-left: 10px;
  }
</style>
