<template>
  <div class="card arrayitem-container" aria-id="contentIdForA11y3">
    <div
      slot="trigger"
      slot-scope="props"
      class="card-header"
      role="button"
    >
      <p class="card-header-title">
        {{ contentVisible ? "-" : "+" }} Container
      </p>
      <a class="card-header-icon">
        <i :class="props.open ? 'mdi mdi-menu-down' : 'mdi mdi-menu-up'" />
      </a>
    </div>
    <div class="card-content">
      <a class="card-remove-icon" @click="$emit('removeItem')">
        <i class="mdi mdi-close" />
      </a>
      <div class="content" style="border: none;">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: [Array, Object, String],
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
  .title { background: whitesmoke; }
  .content { border: 2px solid whitesmoke; }
  a { color: blue; text-decoration: underline; }
  .card-remove-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--primary-color);
  }
  .card-content {
    padding: 0;
  }
</style>
<style>
.arrayitem-container .form-group {
  width: auto !important;
}
</style>
