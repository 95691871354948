<template>
  <b-autocomplete
    :value="value"
    open-on-focus
    :disabled="disabled"
    :icon="icon"
    :data="filteredDataArray"
    @input="$emit('input', $event)"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: undefined,
    },
    data: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredDataArray() {
      return this.data.filter((option) => option
        .toString()
        .toLowerCase()
        .indexOf(this.value.toLowerCase()) >= 0);
    },
  },
};
</script>
