<template>
  <div v-attributes="schema.attributes">
    <div style="display: flex">
      <span class="checkbox" v-if="schema.showEnableCheckbox">
        <input type="checkbox" v-model="isEnabled" />
      </span>
      <b-input v-model="computedValue" class="field-text" type="text" :disabled="schema.disabled" style="flex-grow:1"/>
    </div>
    <div v-if="message" :class="message.class">
      {{message.text}}
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
  data() {
    return {
      message: undefined,
    };
  },
  computed: {
    isEnabled: {
      get () {
        if (this.value === false)
          return false;
        else
          return true;
      },
      set (v) {
        if (v) {
          this.value = '';
        } else {
          this.value = false;
        }
      }
    },
    computedValue: {
      get () {
        if (this.value === false) {
          return 'NC';
        } else return this.value;
      },
      set (v) {
        this.value = v;
      }
    }
  },
  watch: {
    value: {
      handler(v) {
        if (this.schema.alert_messages) {
          this.message = undefined;
          for (const a of this.schema.alert_messages) {
            const regex = new RegExp(a.regex);
            if (regex.test(this.value)) {
              this.message = a.message;
            }
          }
        }
        if (typeof v === 'object') {
          console.error('Wrong type for text field. Model:', this.schema);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.field-text {
  width: 100%;
}
.checkbox {
  border-radius: 100px 0 0 100px;
  background: #ddd;
  width: 18px;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 4px;
  width: 36px;
}
</style>
