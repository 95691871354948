<template>
  <b-taginput
    v-model="value"
    v-attributes="schema.attributes"
    :data="filteredTags"
    :allow-new="true"
    :open-on-focus="true"
    :placeholder="i18n.add_a_field"
    autocomplete
    icon="label"
    class="taginput"
    append-to-body
    @typing="getFilteredTags"
  />
</template>
<script>
import { abstractField } from 'vue-form-generator';
import i18n from 'i18n/components/vfg/FieldSchemaproperties.json';

export default {
  name: 'FieldSchemaproperties',
  mixins: [abstractField],
  data() {
    return {
      i18n,
      tags: [],
    };
  },
  computed: {
    filteredTags () {
      let schemaProperties = [];
      const schemaName = this.schemaName;
      console.log('schemaName', schemaName, this.$parent.model, this.$parent.$parent.model);
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      if (schemas) {
        for (let i = 0; i < schemas.length; i++) {
          if (schemas[i].name === schemaName && schemas[i].fields && schemas[i].fields.length) {
            schemaProperties = schemas[i].fields.map((f) => f.model);
          }
        }
      }
      return schemaProperties;
    },
    schemaName () {
      if (this.schema.schemaName) {
        if (typeof this.schema.schemaName === 'string') {
          return this.schema.schemaName;
        } else if (typeof this.schema.schemaName === 'function') {
          return this.schema.schemaName(this.$parent.$parent.model);
        }
      }
      return this.$parent.$parent.model.schema;
    }
  },
  methods: {
    getFilteredTags(text) {
      if (this.properties) {
        this.filteredTags = this.properties.filter((option) => option.user.first_name
          .toString()
          .toLowerCase()
          .includes(text.toLowerCase()) === true);
      }
    },
  },
};
</script>
<style scoped>
.taginput {
  width: 100%;
}
</style>
