<template>
  <div v-attributes="schema.attributes" class="field-schema-properties-editor">
    <draggable v-if="value" v-model="value" handle=".drag-handle">
      <div v-for="(p, i) in value" :key="p.model" :class="`card ${isEditedProperty(p) ? 'edited': ''}`">
        <EditPropertyMenu
          v-if="editedProperty"
          :value="editedPropertyCopy"
          :all-properties="value"
          @save="saveProperty(editedPropertyIndex, editedPropertyCopy)"
          @close="editedProperty = undefined"
          @delete="deleteProperty(p)"
          @input="editedPropertyCopy = $event"
        />
        <PropertyRow v-if="isEditedProperty(p)" :p="editedProperty" @edit="editProperty(p, i)" />
        <PropertyRow v-else :p="p" @edit="editProperty(p, i)" />
        <div v-if="advancedEditorOpened && editedProperty === p" style="clear: both; margin-top: 10px; border-bottom: 1px solid #e6e6e6" />
      </div>
    </draggable>
    <button class="button new-prop-button is-primary" @click="addProperty">
      + {{ i18n.new }}
    </button>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import draggable from 'vuedraggable';
import EditPropertyMenu from '@/components/EditPropertyMenu';
import deepClone from '@/core/utils/deepClone';
import i18n from 'i18n/components/vfg/FieldSchemapropertieseditor.json';
import PropertyRow from './FieldSchemapropertieseditor/PropertyRow';

export default {
  name: 'FieldSchemapropertieseditor',
  components: {
    EditPropertyMenu,
    draggable,
    PropertyRow,
  },
  mixins: [abstractField],
  data() {
    return {
      i18n,
      editedPropertyIndex: undefined,
      advancedEditorOpened: false,
      editedProperty: undefined,
      editedPropertyCopy: undefined,
    };
  },
  watch: {
    editedProperty(p) {
      if (p) {
        this.editedPropertyCopy = deepClone(p);
      } else {
        this.advancedEditorOpened = false;
        this.editedPropertyCopy = undefined;
        this.editedPropertyIndex = undefined;
      }
    },
  },
  methods: {
    isEditedProperty(p) {
      return this.editedProperty && JSON.stringify(this.editedProperty) === JSON.stringify(p);
    },
    editProperty(editedProperty, index) {
      this.editedProperty = editedProperty;
      this.editedPropertyIndex = index;
    },
    saveProperty(index, value) {
      this.value[index] = value;
      this.editedProperty = undefined;
    },
    deleteProperty(p) {
      for (let i = 0; i < this.value.length; i++) {
        if (JSON.stringify(this.value[i]) === JSON.stringify(this.editedProperty)) {
          this.value.splice(i, 1);
        }
      }
      this.editedProperty = undefined;
    },
    addProperty() {
      if (this.value === undefined || this.value.length === undefined) {
        this.value = [];
      }
      this.value.push({ label: '', model: '' });
    },
  },
};
</script>
<style scoped>
.card {
  padding: 10px;
  transition: margin-bottom 0.2s, margin-top 0.2s;
  overflow: visible;
}
.card:hover {
  background: #f5f5f5;
}
.card.edited {
  margin-bottom: 8px;
  margin-top: -8px;
}
.card.edited >>> .edit-button {
  background: var(--primary-color-darker);
  color: white;
  border-radius: 100px;
}

.field-schema-properties-editor >>> .form-group, .field-schema-properties-editor >>> .field {
  margin-bottom: 0;
}
.field-schema-properties-editor >>> .mini-property-editor {
  margin-top: -2px;
  margin-bottom: -6px;
}
.new-prop-button {
  margin-top: 14px !important;
  border: none !important;
}
</style>
