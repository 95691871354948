<template>
  <span>
    <a class="drag-handle">
      <i class="mdi mdi-drag" />
    </a>
    <b-tooltip :label="i18n.edit" style="margin-left: 20px; margin-right: 24px;">
      <a class="edit-button" @click="$emit('edit', p)">
        <i class="mdi mdi-pencil" />
      </a>
    </b-tooltip>
    <span style="display: inline-block; width: 25px;">
      <b-tooltip :label="`Type: ${p.type}`">
        <i :class="`mdi mdi-${fieldIcon(p)}`" />
      </b-tooltip>
    </span>
    <b-tooltip :label="p.styleClasses ? p.styleClasses.join(', '): i18n.no_styles_applied" :class="p.styleClasses && p.styleClasses.length ? 'field-styles': 'field-styles empty'">
      <i :class="`mdi mdi-palette`" style="margin-left: 24px; margin-right: 24px;" />
      <span class="count-bubble">
        <span class="count-bubble-label">
          {{ p.styleClasses ? p.styleClasses.length : 0 }}
        </span>
      </span>
    </b-tooltip>
    <span style="font-size: 80%">
      <span style="width: 200px; display: inline-block;">
        {{ p.label }}
      </span>
      {{ p.model }}
    </span>
    <b-tooltip v-if="p.required" label="required">
      <span style="font-size: 80%; color: red;">
        <i class="mdi mdi-asterisk" />
      </span>
    </b-tooltip>
    <b-tooltip v-if="p.isIdentifier" label="identifier">
      <span style="font-size: 80%;">
        <i class="mdi mdi-key" />
      </span>
    </b-tooltip>
    <b-tooltip v-if="p.disabled" label="disabled">
      <span>
        <i class="mdi mdi-lock" />
      </span>
    </b-tooltip>
    <b-tooltip v-if="p.help" :label="p.help">
      <i class="mdi mdi-help-circle" />
    </b-tooltip>
    <span
      v-if="p.validatorFunction && p.validatorFunction.type"
      class="tag is-primary"
      style="float: right"
    >
      validation: {{ p.validatorFunction.type }}
    </span>
  </span>
</template>
<script>
import vfgFields from '@/core/vfgfields';
import i18n from 'i18n/components/vfg/FieldSchemapropertieseditor/PropertyRow.json';

export default {
  name: 'PropertyRow',
  props: {
    p: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { i18n };
  },
  methods: {
    fieldIcon(p) {
      const foundField = vfgFields.fields.find((f) => f.originalLabel === p.type);
      return foundField ? foundField.icon : 'unknown';
    },
  },
};
</script>
<style scoped>
.drag-handle {
  cursor: grabbing;
}
.field-styles.empty {
  color: #aaa;
  position: relative;
}
.count-bubble {
  position: absolute;
  top: 14px;
  left: 34px;
  border-radius: 100% 100%;
  width: 14px;
  height: 14px;
  background: var(--primary-color);
  color: white;
  font-size: 68%;
}
.field-styles.empty .count-bubble {
  background: #999;
}
.count-bubble-label {
  position: absolute;
  top: -1px;
  left: 4px;
}

.edit-button {
  width: 26px;
  height: 26px;
  margin-top: -10px;
  margin-bottom: -4px;
  text-align: center;
  padding-top: 0px;
  display: inline-block;
}
.edit-button i::before {
  margin-top: -2px;
}
</style>
