<template>
  <div>
    <Autocomplete
      v-model="viewTitle"
      v-attributes="schema.attributes"
      class="field-view"
      open-on-focus
      :disabled="schema.disabled"
      icon="database"
      :data="mappedPanels"
      append-to-body
    />
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import { mapState } from 'vuex';
import Autocomplete from '@/components/ui/Autocomplete';

export default {
  components: {
    Autocomplete,
  },
  mixins: [abstractField],
  computed: {
    ...mapState({
      panels: (state) => state.abstractElements.objects.panels.objects,
    }),
    mappedPanels() {
      return this.panels.map(p => p.title);
    },
    viewTitle: {
      get () {
        const search = this.panels.filter(p => p._id === this.value);
        if (search && search.length) {
          return search[0].title;
        }
        return '';
      },
      set (v) {
        const search = this.panels.filter(p => p.title === v);
        if (search && search.length) {
          this.value = search[0]._id;
        } else {
          this.value = undefined;
        }
      },
    }
  },
};
</script>
<style scoped>
.field-collections {
  width: 100%;
}
</style>
