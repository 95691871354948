<template>
  <Autocomplete
    v-model="value"
    v-attributes="schema.attributes"
    class="field-category"
    open-on-focus
    :disabled="schema.disabled"
    icon="folder-open"
    :data="categories"
  />
</template>
<script>
import { abstractField } from 'vue-form-generator';
import { mapState } from 'vuex';
import Autocomplete from '@/components/ui/Autocomplete';

export default {
  components: {
    Autocomplete,
  },
  mixins: [abstractField],
  computed: {
    ...mapState({
      panels: (state) => state.abstractElements.objects.panels.objects,
    }),
    categories() {
      const categoriesObjects = {};
      for (const p of this.panels) {
        categoriesObjects[p.category] = true;
      }

      return Object.keys(categoriesObjects);
    },
  },
};
</script>
<style scoped>
.field-category {
  width: 100%;
}
</style>
