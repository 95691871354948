<template>
  <div v-attributes="schema.attributes">
    <b-input :value="value" class="field-text" type="text" :disabled="schema.disabled" @input="$emit('input', $event)"/>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
};
</script>

