<template>
  <div v-attributes="schema.attributes">
    <a @click="openEditor">
      <span
        v-if="value"
        class="card-header-title"
      >
        {{ value.model }}
      </span>
      <span
        v-else
        class="card-header-title"
      >
        ({{ i18n.nothing }})
      </span>
    </a>
    <div v-if="editorOpened">
      <portal to="portalComplexField">
        <a @click="exitSchemaPropertyEdition">
          <i class="mdi mdi-arrow-left" />
          {{ i18n.back }}
        </a>

        <h3 v-if="value" class="title is-3">
          {{ i18n.edit_property_schema }} <b-tag size="is-large">
            {{ value.label || value.model }}
          </b-tag>
        </h3>

        <SchemaPropertyEditor v-model="value" :schema="$parent.$parent.schema" />
      </portal>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import SchemaPropertyEditor from '@/components/SchemaPropertyEditor';
import i18n from 'i18n/components/vfg/FieldSchemaProperty.json';

export default {
  name: 'FieldSchemaProperty',
  components: {
    SchemaPropertyEditor,
  },
  mixins: [abstractField],
  data() {
    return {
      i18n,
      editorOpened: false,
    };
  },
  methods: {
    openEditor() {
      this.editorOpened = true;
    },
    exitSchemaPropertyEdition() {
      this.editorOpened = false;
    },
  },
};
</script>
