<template>
  <div v-attributes="schema.attributes" class="field-propertyvisibility">
    <b-collapse :open="false" class="card">
      <div slot="trigger" slot-scope="props" class="card-header" role="button">
        <a class="card-header-icon">
          <i
            :class="props.open ? 'mdi mdi-menu-down' : 'mdi mdi-menu-right'"
          />
        </a>
        <p v-if="!value" class="card-header-title">
          0 {{ i18n.rules }} <span>&nbsp;- {{ i18n.visible_for_everybody }}</span>
        </p>
        <p v-else class="card-header-title">
          {{ value.length }} {{ i18n.rules }} <span v-if="value.length === 0">&nbsp;- {{ i18n.visible_for_everybody }}</span>
        </p>
      </div>
      <div class="card-content">
        <div class="content">
          <div v-if="value">
            <div v-for="(c, i) in value" :key="c">
              <VisibilityCondition :condition="c" @delete="value.splice(i, 1)" />
            </div>
          </div>
          <button class="button is-primary" @click="addCondition">
            {{ i18n.add_condition }}
          </button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import i18n from 'i18n/components/vfg/FieldPropertyvisibility.json';
import VisibilityCondition from './FieldVisibility/VisibilityCondition';

export default {
  name: 'FieldPropertyvisibility',
  components: {
    VisibilityCondition,
  },
  mixins: [abstractField],
  data() {
    return { i18n };
  },
  methods: {
    addCondition() {
      if (this.value === undefined) {
        this.value = [];
      }
      this.value.push({ type: 'userpermission', params: 'group:edf' });
    },
    changeValue(index, value) {
      this.value[index] = value;
      this.value = [...this.value];
    },
  },
};
</script>
<style scoped>
.button.is-primary {
  background: var(--primary-color);
  color: white;
  border: 0;
}
.button.is-primary:hover {
  background: var(--primary-color-darker);
  color: white;
}
.card-header-title, .card-header-icon {
  margin: 0 !important;
  padding: 6px;
}
</style>
