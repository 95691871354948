<template>
  <span v-attributes="schema.attributes" class="field-undefined">
    {{ i18n.field_not_configured }}
  </span>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import i18n from 'i18n/components/vfg/FieldUndefined.json';

export default {
  name: 'FieldUndefined',
  mixins: [abstractField],
  data() {
    return { i18n };
  },
};
</script>
<style scoped>
.field-undefined {
  width: 100%;
  background: #eee;
  border-radius: 8px;
  font-size: 75%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}
</style>
