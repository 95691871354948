<template>
  <div class="field-week" v-attributes="schema.attributes" >
    <WeekPicker v-model="value" :disabled="schema.disabled" />
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

import WeekPicker from '../ui/WeekPicker';

export default {
  components: {
    WeekPicker,
  },
  mixins: [abstractField],
};
</script>
