<template>
  <IconSelector v-attributes="schema.attributes" v-model="value" class="field-icon-selector" :position="schema.position"/>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import IconSelector from '../ui/IconSelector';

export default {
  mixins: [abstractField],
  components: {
    IconSelector,
  },
};
</script>
