<template>
  <div v-attributes="schema.attributes">
    <Checklist :value="value" style="width: 100%" :choices="schema.values" :disabled="schema.disabled" @input="setValue" />
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import Checklist from '@/components/ui/Checklist';

export default {
  name: 'FieldCustomChecklist',
  components: {
    Checklist,
  },
  mixins: [abstractField],
  computed: {
    unlistedValues() {
      const res = [];
      const valueKeys = this.schema.values.map((v) => v.value);
      if (this.value) {
        for (let i = 0; i < this.value.length; i++) {
          if (!valueKeys.includes(this.value[i])) {
            res.push(this.value[i]);
          }
        }
      }
      return res;
    },
  },
  methods: {
    setValue(value) {
      console.log('CL setValue', value);
      this.value = value;
    },
  },
};
</script>
<style scoped>
.card {
  width: 100%;
}
.card-header-title, .card-header-icon {
  margin: 0 !important;
  padding: 6px;
}
.card-content {
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}
.item {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  padding-left: 36px;
  padding-right: 12px;
  min-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.item i {
  position: absolute;
  left: 6px;
  top: 4px;
  font-size: 140%;
}
.item.selected {
  background: #dedede;
}
.item:hover {
  background: #f0f0f0;
}
.description {
  font-size: 75%;
}
</style>
