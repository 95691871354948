<template>
  <div v-attributes="schema.attributes" class="field-files">
    <b-loading :is-full-page="true" :active="loading" :can-cancel="false" />
    <form enctype="multipart/form-data">
      <div :class="{ dragOver }" class="fields fileInputWrapper no-print">
        <label :for="`file-${elementId}`">
          <i class="mdi mdi-plus" />
          {{ i18n.upload_file }}
        </label><br>
        <input
          :id="`file-${elementId}`"
          ref="file"
          class="fileInput"
          type="file"
          multiple="multiple"
          @change="onSelect"
          @mouseover="dragOver = true"
          @mouseout="dragOver = false"
          @dragover="dragOver = true"
          @dragleave="dragOver = false"
          @dragend="dragOver = false"
          @drop="dragOver = false"
        >
      </div>
      <div v-if="errors">
        <div v-for="(e, i) in errors" :key="i" class="error">
          <i class="mdi mdi-alert" />&nbsp; ({{ e.fileName }}) {{ e.error }}
          <a @click="errors.splice( i,1 )">
            <i class="mdi mdi-close" />
          </a>
        </div>
      </div>
      <div v-if="uploading" class="card uploading-card no-print">
        <div class="card-header-title">
          <span class="file-progress-label">
            <i class="mdi mdi-progress-upload" /> {{ i18n.uploading_file }} ({{ uploading.fileName }})
          </span>
          <span class="file-progress-actions">
            <b-progress class="file-progress-bar" />
            <a @click="abortUpload">
              <i class="mdi mdi-close" />
            </a>
          </span>
        </div>
      </div>
      <div v-if="value">
        <div v-for="fileUploaded in value" :key="fileUploaded._id" class="card">
          <a class="card-header" @click="openFile(fileUploaded)">
            <span class="card-header-title">
              <i class="mdi mdi-file" />&nbsp; {{ fileUploaded.originalname }}
            </span>
            <a v-if="fileUploaded.originalname.split('.').pop() === 'pdf'" class="card-header-icon no-print" @click.stop.prevent="viewFile(fileUploaded)">
              <i class="mdi mdi-eye" />
            </a>
            <a v-if="!schema.disabled" class="card-header-icon no-print" @click.stop.prevent="deleteFile(fileUploaded)">
              <i class="mdi mdi-delete" />
            </a>
          </a>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import { mapState } from 'vuex';
import { ToastProgrammatic as Toast } from 'buefy';
import axios from 'axios';
import Api from '@/core/Api';
import i18n from 'i18n/components/vfg/FieldFiles.json';

export default {
  name: 'FieldFiles',
  components: {},
  mixins: [abstractField],
  data() {
    return {
      i18n,
      elementId: Math.floor(Math.random() * 100000).toString(),
      file: '',
      loading: false,
      errors: [],
      uploading: false,
      cancelUpload: undefined,
      dragOver: false,
    };
  },
  computed: {
    ...mapState({
      addedElement: (state) => state.panels.addedElement,
      editedElement: (state) => state.panels.editedElement,
    }),
    uploadButtonVisible() {
      if (this.schema.disabled) {
        return false;
      }
      if (!this.schema.maxFiles) {
        return true;
      } if (!this.value || !this.value.length || this.value.length < this.schema.maxFiles) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async deleteFile(file) {
      const payload = await Api.delete(`/system_files/${file._id}`);
      if (payload.data.success || (payload.data.success === false && payload.data.error && payload.data.error.message === 'record not found')) {
        for (let i = 0; i < this.value.length; i++) {
          if (this.value[i]._id === file._id) {
            this.value.splice(i, 1);
            return;
          }
        }
      }
    },
    onSelect() {
      // const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      const { files } = this.$refs.file;
      // if (!allowedTypes.includes(file.type)) {
      //   this.message = 'Filetype is wrong!!';
      // }
      // if (file.size > 500000) {
      //   this.message = 'Too large, max size allowed is 500kb';
      // }
      if (files) {
        this.onSubmit(files);
      }
    },
    viewFile(file) {
      this.$store.dispatch('fileViewer/setFileDisplayed', file);
    },
    openFile(file) {
      this.loading = true;
      const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:3332';
      axios({
        url: `${baseUrl}/system_files/${file._id}/file`,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Token ${this.$store.state.login.user.token}`,
        },
      }).then((response) => {
        this.loading = false;
        if (response.data && response.data.success === false) {
          this.fileDownloadError();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch((e) => {
        this.loading = false;
        this.fileDownloadError();
      });
    },
    fileDownloadError() {
      Toast.open({
        message: i18n.could_not_open_file,
        type: 'is-danger',
      });
    },
    async onSubmit(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.uploading = { fileName: file.name };
        const formData = new FormData();
        let parentId;
        if (this.addedElement) {
          parentId = this.addedElement._id;
        }
        if (this.editedElement) {
          parentId = this.editedElement._id;
        }
        formData.append('file', file);
        console.log('file', file);
        formData.append('group', this.$parent.model.group);
        formData.append('parentId', parentId);
        try {
          const payload = await Api.post('/system/fileUpload', formData, {
            cancelToken: new axios.CancelToken((c) => { this.cancelUpload = c; }),
          });
          this.uploading = undefined;
          if (this.value === undefined) {
            this.value = [];
          }
          this.value.push({
            _id: payload.data.document._id,
            originalname: payload.data.document.originalname,
            mimetype: payload.data.document.mimetype,
          });
        } catch (err) {
          this.uploading = undefined;
          this.errors.push({ error: err, fileName: file.name });
        }
      }
      this.$refs.file.value = '';
    },
    abortUpload() {
      if (this.cancelUpload) {
        this.cancelUpload();
      }
    },
  },
};
</script>
<i18n>
{
  "en": {
    "upload_file": "Upload file",
    "uploading_file": "Uploading file",
    "could_not_open_file": "Could not open file"
  },
  "fr": {
    "upload_file": "Ajouter un ou plusieurs fichiers",
    "uploading_file": "Envoi du fichier",
    "could_not_open_file": "Impossible d'ouvrir le fichier"
  }
}
</i18n>
<style scoped>
label {
  display: inline-block;
  padding: 6px 12px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left !important;
  white-space: normal;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}
label:hover, .dragOver label {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.fileInputWrapper {
  position: relative;
}

.fileInput {
  /*display: none;*/
  opacity: 0;
  position: absolute;
  border: 1px solid red;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
}

.card {
  padding: 8px;
  cursor: pointer;
}
.card .card-header-icon {
  transition: margin-top 0.2s, margin-bottom 0.2s, font-size 0.2s;
}

.card {
  transition: background 0.2s, box-shadow 0.2s,;
  background: #f9f9f9 !important;
  border: 0 !important;
  padding: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.card:hover {
  background: #CCCCCC22;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.1)
}

.card:hover .card-header-icon {
  margin-top: -6px;
  margin-bottom: -7px;
  font-size: 24px;
}
.error {
  background: #fff5f7;
  padding: 10px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0 0 0 4px;
  border-color: #ff3860;
  color: #cd0930;
  margin: 2px;
}

.error i {
  color: #cd0930;
}
.error .mdi-close {
  float: right;
}
.file-progress-label, .file-progress-actions {
  width: 50%;
  margin-right: 50px;
  margin-left: 50px;
}
.uploading-card, .uploading-card > div {
  background: #7957d5 !important;
  color: white !important;
  font-weight: bold;
}
.file-progress-bar {
  width: calc(100% - 60px);
  float: left;
  padding-top: 4px;
  margin-bottom: 0px;
}
.file-progress-actions a {
  float: right;
  color: white;
  margin-left: 20px;
}
</style>
