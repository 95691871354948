<template>
  <InputGroup v-model="value" v-attributes="schema.attributes" />
</template>
<script>
import { abstractField } from 'vue-form-generator';

import InputGroup from '@/components/ui/InputGroup';

export default {
  components: {
    InputGroup,
  },
  mixins: [abstractField],
};
</script>
