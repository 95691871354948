<template>
  <div v-attributes="schema.attributes" class="permissions-field">
    <div v-if="value">
      <div v-for="(p, i) in permissions" :key="i">
        <b-select v-model="p[0]" class="field-part" size="is-small" style="display: inline-block;" expanded>
          <option value="collection">
            {{ i18n.collection }}
          </option>
          <option value="group">
            {{ schema.groupLabel || i18n.group }}
          </option>
          <option value="action">
            {{ i18n.action }}
          </option>
          <option value="role">
            {{ i18n.role }}
          </option>
        </b-select>
        <b-select
          v-if="p[0] === 'role'"
          v-model="p[1]"
          class="field-part"
          size="is-small"
          style="display: inline-block;"
          expanded
          @input="p[2] = 'all'"
        >
          <option v-for="r in roles" :key="r" :value="r">
            {{ r }}
          </option>
          <option v-for="(o, r) in config.additionnal_roles" :key="r" :value="r">
            {{ r }}
          </option>
        </b-select>
        <b-select
          v-if="p[0] === 'action'"
          v-model="p[1]"
          class="field-part"
          size="is-small"
          style="display: inline-block;"
          expanded
          @input="p[2] = 'all'"
        >
          <option v-for="a in actions" :key="a" :value="a">
            {{ a }}
          </option>
        </b-select>
        <b-input
          v-if="p[0] === 'collection' || p[0] === 'group'"
          v-model="p[1]"
          class="field-part"
          type="text"
          size="is-small"
          style="display: inline-block;"
          expanded
          @input="p[2] = 'all'"
        />
        <span v-if="p[0] === 'collection' || p[0] === 'group'" class="radios">
          <span>
            <label for="undefined">All</label>
            <input id="undefined" v-model="p[2]" type="radio" value="all">
          </span>
          <span>
            <label for="read">Read</label>
            <input id="read" v-model="p[2]" type="radio" value="read">
          </span>
          <span>
            <label for="create">Create</label>
            <input id="create" v-model="p[2]" type="radio" value="create">
          </span>
          <span>
            <label for="update">Update</label>
            <input id="update" v-model="p[2]" type="radio" value="update">
          </span>
          <span>
            <label for="delete">Delete</label>
            <input id="delete" v-model="p[2]" type="radio" value="delete">
          </span>
        </span>
        <a class="button" style="flex-grow: 0" @click="deletePermission(i)">
          <i class="mdi mdi-delete" />
        </a>
      </div>
      <a class="button" style="width: 100%" @click="addPermission">
        <i class="mdi mdi-plus" /> Add permission
      </a>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import { mapState } from 'vuex';
import roles from '@/permissions/roles';
import actions from '@/permissions/actions';
import i18n from 'i18n/components/vfg/FieldPermissions.json';

export default {
  name: 'FieldPermissions',
  mixins: [abstractField],
  data() {
    if (!this.value) {
      this.value = [];
    }
    return {
      i18n,
      permissions: this.computeValue(),
      roles: Object.keys(roles),
      actions: Object.keys(actions),
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.config.config,
    }),
  },
  watch: {
    permissions: {
      handler(v) {
        this.value = (v ? v.map((p) => {
          const current = [...p];
          if (current[2] === 'all') {
            current.splice(2, 1);
          }
          let newPermission = current.join(':');
          if (newPermission === ':' || newPermission === '::') {
            newPermission = undefined;
          }

          return newPermission;
        }).filter((p) => !!p) : undefined);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    computeValue() {
      return this.value ? this.value.map((p) => {
        const current = ((p && p.split) ? p.split(':') : ['', '', 'all']);
        if (!current[2]) {
          current[2] = 'all';
        }
        return current;
      }) : undefined;
    },
    addPermission() {
      this.permissions.push(['', '', 'all']);
    },
    deletePermission(index) {
      if (this.permissions[index]) {
        this.permissions.splice(index, 1);
      }
    },
  },
};
</script>
<i18n>
{
  "en": {
    "collection": "Collection",
    "group": "Group",
    "action": "Action",
    "role": "Role"
  },
  "fr": {
    "collection": "Collection",
    "group": "Groupe",
    "action": "Action",
    "role": "Role"
  }
}
</i18n>
<style scoped>
.permissions-field {
  width: 100%;
}
.permissions-field > div {
  width: 100%;
}
.permissions-field > div > div {
  display: flex;
  width: 100%;
}
.permissions-field .field-part {
  min-width: 100px;
}
.radios {
  width: 800px;
  display: inline-flex;
  margin-right: 32px;
  margin-left: 32px;
}
.radios > span {
  flex-grow: 1;
}
.radios input {
  margin-top: 8px;
  width: 32px !important;
  height: 16px;
}
.radios label {
  padding-right: 0 !important;
  padding-left: 20px;
}
</style>
