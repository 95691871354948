<template>
  <div v-attributes="schema.attributes" class="field-simple-relation">
    <div v-if="valueFormatted">
      <div class="card button-card" v-for="e in valueFormatted" :key="e._id" @click="editorOpened = !editorOpened">
        <div class="card-content">
          <a style="float: right" @click.prevent.stop="removeDocument(e)">
            <i class="mdi mdi-close" />
          </a>
          <span v-for="f in schema.onlyFields" :key="f" style="padding-right: 20px">
            {{e[f]}}
          </span>
        </div>
      </div>
      <div class="card button-card" v-if="schema.relationType === 'Multiple'" @click="editorOpened = !editorOpened">
        <div class="card-content">
          <i class="mdi mdi-plus" />
          <i> Ajouter un élément</i>
        </div>
      </div>
    </div>
    <div v-if="!valueFormatted || !valueFormatted.length" class="card button-card" @click="editorOpened = !editorOpened">
      <div class="card-content">
        <i>Aucun élément sélectionné</i>
      </div>
    </div>
    <div v-if="editorOpened">
      <portal to="portalComplexField">
        <div class="complex-field-editor document-field">
          <a class="back-button" @click.stop="editorOpened = false">
            <i class="mdi mdi-arrow-left" />
            Retour
          </a>
          <h1 class="title is-2">Ajouter un enregistrement à la relation</h1>
          <a v-for="r in allResults" :key="r._id" class="card button-card" @click="selectDocument(r)">
            <div class="card-content">
              <span v-for="f in schema.onlyFields" :key="f" style="padding-right: 20px">
                {{r[f]}}
              </span>
            </div>
          </a>
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import { mapState } from 'vuex';

export default {
  mixins: [abstractField],
  data () {
    return {
      editorOpened: false,
    };
  },
  mounted () {
    this.$store.dispatch('abstractElements/fetchObjects', {
      collection: this.selectedPanel.collection,
      destinationBucket: this.bucket,
      aggregate: this.selectedPanel.aggregate,
      sort: this.selectedPanel.sort,
    });
  },
  computed: {
    ...mapState({
      panels: (state) => state.abstractElements.objects.panels.objects,
    }),
    selectedPanel () {
      return this.panels.filter(p => p._id === this.schema.view)[0];
    },
    valueFormatted: {
      get () {
        if(Array.isArray(this.value)) {
          return this.value.filter(e => e !== null);
        } else {
          if (this.value) {
            return [this.value];
          }
        }
      }
    },
    bucket () {
      if(this.selectedPanel) {
        const bucketNamePart = this.selectedPanel.bucket || this.selectedPanel.collection;
        return 'fieldSimpleRelation-' + bucketNamePart;
      } else {
        return undefined;
      }
    },
    allResults: {
      get() {
        if (!this.selectedPanel.collection || !this.$store.state.abstractElements.objects[this.bucket] || !this.$store.state.abstractElements.objects[this.bucket].objects) {
          return [];
        }
        return this.$store.state.abstractElements.objects[this.bucket].objects;
      },
    },
  },
  methods: {
    selectDocument(d) {
      if (this.schema.relationType === 'Multiple') {
        if (this.value === undefined) {
          this.value = [];
        }
        this.value.push(d);
      } else {
        this.value = d;
      }
      this.editorOpened = false;
    },
    removeDocument (d) {
      if (Array.isArray(this.value)) {
        this.value.splice(this.value.indexOf(d), 1);
      } else {
        this.value = undefined;
      }
    }
  }
};
</script>
<style>
.button-card {
  background: #f9f9f9;
  cursor: pointer;
}
.button-card > .card-content,
.button-card > .card-header-title {
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}
</style>
