<template>
  <span v-attributes="schema.attributes" class="field-undefined">
    <QrCode v-if="qrcodedata" :value="qrcodedata" />
    <div v-else>
      Pas de QR code disponible.
    </div>
  </span>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import Handlebars from 'handlebars';
import QrCode from '@/components/ui/QrCode';

export default {
  name: 'FieldQrcode',
  components: {
    QrCode,
  },
  mixins: [abstractField],
  computed: {
    qrcodedata() {
      if (this.schema.qrcodedata) {
        const template = Handlebars.compile(this.schema.qrcodedata);
        return template({});
      }
    },
  },
};
</script>
<style scoped>
</style>
