<template>
  <div v-attributes="schema.attributes" class="field-empty">
    {{schema.text || ''}}
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
};
</script>
