<template>
  <div v-attributes="schema.attributes" class="field-date">
    <button class="button reset-value-button no-print" :disabled="schema.disabled" @click="value = undefined">
      <i class="mdi mdi-close" />
    </button>
    <DatePicker v-model="valueAsDate" :disabled="schema.disabled" />
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import DatePicker from '../ui/DatePicker';

export default {
  components: {
    DatePicker,
  },
  mixins: [abstractField],
  computed: {
    valueAsDate: {
      get() {
        if (this.value && this.value !== '') {
          return new Date(this.value);
        }
        return undefined;
      },
      set(v) {
        const formatDatePart = (part) => {
          part = '' + part;
          if(part.length === 1) {
            part = '0' + part;
          }
          return part;
        }
        this.value = `${v.getFullYear()}-${formatDatePart(v.getMonth() + 1)}-${formatDatePart(v.getDate())}`;
      },
    },
  },
};
</script>
<style scoped>
.field-date {
  position: relative;
}
.reset-value-button {
  position: absolute;
  right: 0;
  top: 2px;
  z-index: 1;
}
</style>
