<template>
  <span>
    <b-message v-if="value === undefined || value === null">
      No session token registered yet.
    </b-message>
    <canvas ref="canvas" v-else />
    <a class="button" @click="generateToken">
      Generate a token
    </a>
  </span>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import { mapState } from 'vuex';
import Autocomplete from '@/components/ui/Autocomplete';
import QRCode from 'qrcode';

const canvas = document.getElementById('canvas');

export default {
  components: {
    Autocomplete,
  },
  mixins: [abstractField],
  mounted() {
    this.generateCanvas();
  },
  methods: {
    generateCanvas() {
      QRCode.toCanvas(this.$refs.canvas, this.value.currentToken, (error) => {
        if (error) {
          console.error(error);
        } else {
          console.log('success!');
        }
      });
    },
    generateToken() {
      let { value } = this;
      if (value === undefined || value === null) {
        value = {};
      }
      if (!value.revokedTokens) {
        value.revokedTokens = [];
      }
      if (value.currentToken) {
        value.revokedTokens.push(value.currentToken);
      }
      value.currentToken = Math.random().toString();
      this.value = value;
      this.$nextTick(() => {
        this.generateCanvas();
      });
    },
  },
};
</script>
