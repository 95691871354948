<template>
  <div v-attributes="schema.attributes" class="code-portal">
    <div>
      <a
        @click="openEditor"
      >
        <span
          v-if="value"
          class="card-header-title"
        >
          <i class="mdi mdi-file-document-box" />
          &nbsp;
          {{ value[labelField] }}
        </span>
        <a
          v-else
          class="card-header-title"
          @click="openEditor"
        >
          ({{ i18n.nothing }})
        </a>
      </a>
    </div>
    <div v-if="editorOpened">
      <portal to="portalComplexField">
        <div class="complex-field-editor">
          <a @click="editorOpened = false">
            <i class="mdi mdi-arrow-left" />
            Back
          </a>
          <h3 class="title is-3">
            Edit property
            <span v-if="value[labelField]" class="tag">
              {{ value[labelField] }}
            </span>
          </h3>
          <h4 v-if="schema.description" class="subtitle is-4">
            {{ schema.description }}
          </h4>
          <div class="content">
            <codemirror
              v-model="valueAsString"
              :options="editorOptions"
              class="editor field-editor"
            />
          </div>
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/vue/vue';
import 'codemirror/theme/idea.css';
import i18n from 'i18n/components/vfg/FieldCodePortal.json';

import JSON5 from 'json5';

export default {
  name: 'FieldCodePortal',
  components: {
    codemirror,
  },
  mixins: [abstractField],
  data() {
    return {
      i18n,
      labelField: this.schema.labelField || 'title',
      editorOpened: false,
      valueAsString: JSON5.stringify(this.value, null, 2),
      editorOptions: {
        tabSize: 2,
        lineWrapping: true,
        mode: { name: 'javascript' },
        theme: 'idea',
        lineNumbers: true,
        line: true,
        autofocus: true,
        keyMap: 'sublime',
        autoCloseBrackets: true,
        readOnly: this.schema.disabled,
      },
    };
  },
  watch: {
    valueAsString(v) {
      try {
        this.value = JSON5.parse(v);
      } catch (e) {}
    },
  },
  methods: {
    openEditor() {
      this.editorOpened = true;
    },
  },
};
</script>
