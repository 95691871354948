<template>
  <div v-attributes="schema.attributes" class="field-documents">
    <div class="card">
      <a
        class="card-header"
        @click="openEditor"
      >
        <span
          v-if="value"
          class="card-header-title"
        >
          <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document-box'}`" />
          &nbsp;
          {{ value.length }} elements
        </span>
        <a
          v-else
          class="card-header-title"
          @click="openEditor"
        >
          ({{ schema.emptyLabel || i18n.nothing }})
        </a>
        <a class="card-header-icon">
          <i class="mdi mdi-magnify" />
          <i class="mdi mdi-menu-right" />
        </a>
      </a>
    </div>
    <div v-if="schema.showItemList">
      <div class="card" v-for="(d, i) in value" :key="d._id">
        <div class="card-header">
          <span class="card-header-title">
            {{d[schema.showItemList.labelKey]}}
          </span>
          <a v-if="!offlineDocSelected" class="card-header-icon no-print" @click="editDocument(d, i)">
            <i class="mdi mdi-pencil" />
          </a>
          <a class="card-header-icon no-print" @click="deleteDocument(d, i)">
            <i class="mdi mdi-close" />
          </a>
        </div>
      </div>
    </div>
    <b-dropdown v-if="schema.addValueProposals">
      <template #trigger="{ active }">
        <a class="button">
          Ajouter
          <i class="mdi mdi-menu-down" />
        </a>
      </template>
      <b-dropdown-item v-for="(p, nom) in schema.addValueProposals" :key="nom" @click="openNewDocumentModal(p)">
        {{nom}}
      </b-dropdown-item>
    </b-dropdown>
    <div v-if="newDocumentModel">
      <EditModal
        :edited-model="newDocumentModel"
        :show-delete-button="false"
        title="New document"
        :config="{collection}"
        guess-schema-name
        @save="createDocument"
        @close="newDocumentModel = undefined"
      />
    </div>
    <div v-if="editedDocument">
      <EditModal
        :edited-model="editedDocument"
        :show-delete-button="false"
        title="Edit document"
        :config="{collection}"
        guess-schema-name
        fetch-object-on-backend
        @save="saveDocument"
        @close="editedDocument = undefined"
      />
    </div>
    <div v-if="editorOpened">
      <portal to="portalComplexField">
        <div class="complex-field-editor documents-field">
          <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
          <a class="back-button" @click="editorOpened = false">
            <i class="mdi mdi-arrow-left" />
            {{ i18n.back }}
          </a>
          <div class="field-header">
            <h3 v-if="schema.titleLabel && schema.titleLabel !== ''" class="title is-3">
              {{ schema.titleLabel }}
            </h3>
            <h3 v-else-if="schema.titleLabel !== ''" class="title is-3">
              {{ i18n.edit_property }} {{ schema.label ? `"${schema.label}"` : '' }}
            </h3>
            <h4 v-if="schema.subtitleLabel && schema.subtitleLabel !== ''" class="subtitle is-4">
              {{ schema.subtitleLabel }}
            </h4>
            <h4 v-else-if="schema.subtitleLabel !== ''">
              {{ i18n.associate_a_document_from_the }} <span class="tag">{{ collection }}</span> {{ i18n.collection }}
            </h4>
          </div>
          <div class="content">
            <b-message v-if="error" type="is-danger" has-icon>
              {{ error }}
            </b-message>
            <div v-if="search" class="search-container">
              <SearchField v-model="searchText" class="search" />
              <b-dropdown v-if="filters" v-model="activeFilter" aria-role="list" position="is-bottom-left">
                <button slot="trigger" class="button" type="button">
                  <span>
                    <i class="mdi mdi-filter" /> {{ (activeFilter && activeFilter.query) ? activeFilter.label : i18n.everything }}
                  </span>
                  <i class="mdi mdi-menu-down" />
                </button>
                <b-dropdown-item :value="{}" aria-role="listitem">
                  {{ i18n.everything }}
                </b-dropdown-item>
                <b-dropdown-item v-for="f in filters" :key="f.label" :value="f" aria-role="listitem">
                  <div class="media">
                    <div class="media-content">
                      {{ f.label }}
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <b-tabs v-if="allGroups && allGroups.length && groupedResults" type="is-boxed" vertical>
              <b-tab-item :label="i18n.selected + ` (${ selectedResults.length })`">
                <table v-if="relationSchema && relationSchema.fields && relationSchema.fields.length" class="table is-striped is-hoverable is-fullwidth results">
                  <thead>
                    <tr>
                      <th style="width: 40px" />
                      <th v-for="p in relationSchema.fields" :key="p.model">
                        {{ p.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="r in selectedResults" :key="r._id" :class="`${isSelected(r) ? 'selected': ''}`" @click="selectDocument(r)">
                      <td>
                        <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document-box'}`" />
                        <span v-if="r._id === undefined" class="tag is-warning">
                          Non publié
                        </span>
                      </td>
                      <td v-for="p in relationSchema.fields" :key="p.model">
                        {{ r[p.model] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="table is-bordered is-striped is-hoverable is-fullwidth results">
                  <div v-for="r in selectedResults" :key="r._id" :class="`card ${isSelected(r) ? 'selected': ''}`" @click="selectDocument(r)">
                    <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document-box'}`" />
                    <span v-if="r._id === undefined" class="tag is-warning">
                      Non publié
                    </span>
                    {{ r[labelField] }}
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item v-for="g in allGroups" :key="g" :label="g">
                <table v-if="relationSchema && relationSchema.fields && relationSchema.fields.length" class="table is-striped is-hoverable is-fullwidth results">
                  <thead>
                    <tr>
                      <th style="width: 40px" />
                      <th v-for="p in relationSchema.fields" :key="p.model">
                        {{ p.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="r in groupedResults[g]" :key="r._id" :class="`${isSelected(r) ? 'selected': ''}`" @click="selectDocument(r)">
                      <td>
                        <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document-box'}`" />
                      </td>
                      <td v-for="p in relationSchema.fields" :key="p.model">
                        {{ r[p.model] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="table is-bordered is-striped is-hoverable is-fullwidth results">
                  <div v-for="r in groupedResults[g]" :key="r._id" :class="`card ${isSelected(r) ? 'selected': ''}`" @click="selectDocument(r)">
                    <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document-box'}`" />
                    {{ r[labelField] }}
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
            <div v-else>
              <table v-if="relationSchema && relationSchema.fields && relationSchema.fields.length" class="table is-striped is-hoverable is-fullwidth results">
                <thead>
                  <tr>
                    <th style="width: 40px" />
                    <th v-for="p in relationSchema.fields" :key="p.model">
                      {{ p.label }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in results" :key="r._id" :class="`${isSelected(r) ? 'selected': ''}`" @click="selectDocument(r)">
                    <td>
                      <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document-box'}`" />
                    </td>
                    <td v-for="p in relationSchema.fields" :key="p.model">
                      {{ r[p.model] }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="table is-bordered is-striped is-hoverable is-fullwidth results">
                <div v-for="r in results" :key="r._id" :class="`card ${isSelected(r) ? 'selected': ''}`" @click="selectDocument(r)">
                  <i :class="`mdi mdi-${schema.icon ? schema.icon : 'file-document-box'}`" />
                  {{ r[labelField] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import Handlebars from 'handlebars';
import { debounce } from 'vue-debounce';
import SearchField from '@/components/ui/SearchField';
import i18n from 'i18n/components/vfg/FieldDocuments.json';
import deepClone from '@/core/utils/deepClone';

export default {
  name: 'FieldDocuments',
  components: {
    SearchField,
    EditModal: () => import(/* webpackChunkName: "EditModal" */ '@/components/modals/EditModal'),
  },
  mixins: [abstractField],
  data() {
    return {
      i18n,
      searchText: '',
      isLoading: false,
      collection: this.schema.relation.collection,
      labelField: this.schema.labelField || 'title',
      onlyFields: this.schema.onlyFields,
      search: this.schema.search,
      onlyFieldAsString: this.schema.onlyFieldAsString,
      groupKey: this.schema.groupKey,
      filters: this.schema.filters,
      activeFilter: '',
      selectedResults: [],
      allGroups: [],
      groupedResults: undefined,
      query: undefined,
      error: undefined,
      editorOpened: false,
      editedDocument: undefined,
      editedDocumentIndex: undefined,
      offlineDocSelected: undefined,
      newDocumentModel: undefined,
      debouncedSearch: debounce(async () => {
        await this.$store.dispatch(`${this.storeModule || 'abstractElements'}/filterObjects`, {
          bucket: `vfg-field-document-${this.collection}-all`,
          filter: this.activeFilter,
          search: {
            keys: this.schema.onlyFields || [],
            searchTerm: this.searchText,
            fuzzySearch: this.fuzzySearch,
          },
        });
        /*
        if (!this.activeFilter || Object.keys(this.activeFilter).length === 0) {
          this.results = JSON.parse(JSON.stringify(this.allResults));
        } else {
          let query = {};
          if (typeof this.activeFilter.query === 'object') {
            query = this.activeFilter.query;
          } else if (typeof this.activeFilter.query === 'string') {
            query = json5.parse(this.activeFilter.query);
          }
          this.results = JSON.parse(JSON.stringify(this.allResults));
          this.results = this.results.filter(sift(query));
        }
        */
        /*
        const options = {
          shouldSort: true,
          threshold: 0.1,
          limit: 50,
          tokenize: !!this.fuzzySearch,
          keys: this.schema.onlyFields || []
        };
        if (this.searchText && this.searchText !== '') {
          const fuse = new Fuse([...this.results], options);
          this.results = fuse.search(this.searchText);
        }
        */
      }, 500),
    };
  },
  computed: {
    allResults: {
      get() {
        if (!this.collection || !this.$store.state.abstractElements.objects[`vfg-field-document-${this.collection}-all`] || !this.$store.state.abstractElements.objects[`vfg-field-document-${this.collection}-all`].objects) {
          return [];
        }
        return this.$store.state.abstractElements.objects[`vfg-field-document-${this.collection}-all`].objects;
      },
    },
    results: {
      get() {
        if (!this.collection || !this.$store.state.abstractElements.objects[`vfg-field-document-${this.collection}-all`] || !this.$store.state.abstractElements.objects[`vfg-field-document-${this.collection}-all`].filteredObjects) {
          return [];
        }
        return this.$store.state.abstractElements.objects[`vfg-field-document-${this.collection}-all`].filteredObjects;
      },
    },
    relationSchema() {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      if (schemas) {
        for (let i = 0; i < schemas.length; i++) {
          if (schemas[i].name === this.collection) {
            const schema = { ...schemas[i] };
            if (this.onlyFields) {
              schema.fields = schema.fields.filter((f) => this.onlyFields.includes(f.model));
            }
            return schema;
          }
        }
      }
      return { fields: [] };
    },
  },
  watch: {
    searchText: 'doFilter',
    activeFilter: 'doFilter',
    value: 'extractSelectedResults',
    results: {
      handler(results) {
        const {groupKey} = this;
        if (groupKey) {
          const groupedResults = {};
          for (let i = 0; i < results.length; i++) {
            if (groupedResults[results[i][groupKey]] === undefined) {
              groupedResults[results[i][groupKey]] = [];
            }
            groupedResults[results[i][groupKey]].push(results[i]);
          }
          this.groupedResults = groupedResults;
          console.log('groupedResults', groupedResults);
        }
      },
      immediate: true,
      deep: true,
    },
    allResults: {
      handler(v) {
        let results;
        if (this.$store.state.abstractElements.objects[`vfg-field-document-${this.collection}-all`]) {
          results = this.$store.state.abstractElements.objects[`vfg-field-document-${this.collection}-all`].filteredObjects;
        }
        if (results && this.groupKey) {
          this.extractAllGroups();
          this.extractSelectedResults();
          const groupedResults = {};
          const {groupKey} = this;
          for (let i = 0; i < results.length; i++) {
            if (groupedResults[results[i][groupKey]] === undefined) {
              groupedResults[results[i][groupKey]] = [];
            }
            groupedResults[results[i][groupKey]].push(results[i]);
          }
          this.groupedResults = deepClone(groupedResults);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (this.schema.query) {
      try {
        if (typeof this.schema.query === 'string') {
          const template = Handlebars.compile(this.schema.query);
          this.query = template({
            model: this.$parent.model,
          });
        } else {
          this.query = JSON.stringify(this.schema.query);
        }
      } catch (e) {
        console.error('malformed query JSON: ', e, this.schema.query);
      }
    } else {
      this.query = undefined;
    }
    this.queryDocuments();
    const OfflineDocEditModalComponent = this.$el.closest('.offline-doc-edit-modal-component');
    if (OfflineDocEditModalComponent) {
      this.offlineDocSelected = OfflineDocEditModalComponent.__vue__.docSelected;
    }
  },
  methods: {
    extractSelectedResults() {
      if (!this.value) {
        this.value = [];
      }
      if (!this.value.map) {
        this.value = Array.from(this.value);
      }
      const selectedIds = this.value.map((v) => v._id);
      this.selectedResults = this.allResults.filter((r) => selectedIds.includes(r._id));
      const unpublishedSelectedResults = this.value.filter((v) => v._id === undefined);
      for (const r of unpublishedSelectedResults) {
        this.selectedResults.push(r);
      }

      if (this.schema.sort) {
        const generateSortFn = function (props) {
          return function (a, b) {
            for (let i = 0; i < props.length; i++) {
              const prop = props[i];
              const {name} = prop;
              const {direction} = prop;
              if (a[name] < b[name]) { return direction * -1; }
              if (a[name] > b[name]) { return direction * -1; }
            }
            return 0;
          };
        };

        let sortKeys = Object.keys(this.schema.sort);
        sortKeys = sortKeys.map((k) => ({ name: k, direction: this.schema.sort[k] }));
        this.selectedResults = this.selectedResults.sort(generateSortFn(sortKeys));
      }
    },
    extractAllGroups() {
      const {groupKey} = this;
      const res = [];
      if (this.groupKey) {
        for (let i = 0; i < this.allResults.length; i++) {
          if (!res.includes(this.allResults[i][groupKey])) {
            res.push(this.allResults[i][groupKey]);
          }
        }
      }
      this.allGroups = res;
    },
    isSelected(record) {
      if (this.value && this.value.filter((recordSelected) => {
        if (recordSelected._id === record._id) {
          return true;
        }
        if (this.schema.onlyFieldAsString && record[this.schema.onlyFieldAsString] === recordSelected) {
          return true;
        }
        return false;
      }).length >= 1) {
        return true;
      }
    },
    queryDocuments() {
      this.isLoading = true;
      const {collection} = this;
      const destinationBucket = `vfg-field-document-${collection}-all`;
      const bucket = this.$store.state.abstractElements.objects[`vfg-field-document-${collection}-all`];
      if (!bucket || bucket.isLoading !== true) {
        this.$store.dispatch('abstractElements/fetchObjects', {
          collection,
          destinationBucket,
          aggregate: this.schema.aggregate,
          sort: this.schema.sort,
        });
      }
      // const payload = await this.$axios.$get(`/${this.collection}?query=${this.query || '{}'}`);
      this.isLoading = false;
    },
    selectDocument(document) {
      if (this.value === undefined) {
        this.value = [];
      }
      if (this.isSelected(document)) {
        this.value = this.value.filter((d) => d._id !== document._id);
      } else {
        document = { ...document };
        if (this.onlyFields) {
          const docKeys = Object.keys(document);
          for (let i = 0; i < docKeys.length; i++) {
            if (!this.onlyFields.includes(docKeys[i]) && docKeys[i] !== '_id') {
              delete document[docKeys[i]];
            }
          }
        }
        if (this.onlyFieldAsString) {
          this.value.push(document[this.onlyFieldAsString]);
        } else {
          delete document._metadatas;
          this.value.push(document);
        }
      }
    },
    openEditor() {
      this.editorOpened = true;
    },
    doFilter() {
      try {
        this.debouncedSearch();
      } catch (e) {
        console.error(e);
      }
    },
    openNewDocumentModal(defaultValue) {
      this.newDocumentModel = deepClone(defaultValue);
    },
    editDocument(d, i) {
      console.log('editDocument', d);
      this.editedDocument = d;
      this.editedDocumentIndex = i;
    },
    deleteDocument(d, i) {
      console.log('deleteDocument', d, i);
      this.value.splice(i, 1);
    },
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
    createDocument(d) {
      console.log('create', d, d._metadatas.schemaName);
      const storeModule = 'abstractElements';

      this.$store.dispatch(`${storeModule}/createObject`, {
        collection: this.collection,
        destinationBucket: `vfg-field-document-${this.collection}-all`,
        offlineDocSelected: this.offlineDocSelected,
        object: d,
        schema: this.getSchemaByName(d._metadatas.schemaName),
      }).then((res) => {
        console.log('created', JSON.stringify(res.data.document));
        let {document} = res.data;

        console.log('isSelected document false');
        document = { ...document };
        if (this.onlyFields) {
          const docKeys = Object.keys(document);
          for (let i = 0; i < docKeys.length; i++) {
            if (!this.onlyFields.includes(docKeys[i]) && docKeys[i] !== '_id') {
              delete document[docKeys[i]];
            }
          }
        }
        if (this.onlyFieldAsString) {
          this.value.push(document[this.onlyFieldAsString]);
        } else {
          delete document._metadatas;
          this.value.push(document);
        }
        this.queryDocuments();
        this.newDocumentModel = undefined;
      });
    },
    saveDocument(d) {
      console.log('save', d);
      const storeModule = 'abstractElements';
      this.$store.dispatch(`${storeModule}/saveObject`, {
        collection: this.collection,
        destinationBucket: `vfg-field-document-${this.collection}-all`,
        object: d,
      }).then((res) => {
        console.log('saved!', res, res.payload.data.document);
        const document = { ...res.payload.data.document };
        if (this.onlyFields) {
          const docKeys = Object.keys(document);
          for (let i = 0; i < docKeys.length; i++) {
            if (!this.onlyFields.includes(docKeys[i]) && docKeys[i] !== '_id') {
              delete document[docKeys[i]];
            }
          }
        }
        if (this.onlyFieldAsString) {
          this.value[this.editedDocumentIndex] = document[this.onlyFieldAsString];
        } else {
          delete document._metadatas;
          this.value[this.editedDocumentIndex] = document;
        }
        this.editedDocumentIndex = undefined;
        this.editedDocument = undefined;
      });
    },
  },
};
</script>
<style scoped>
.back-button {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}
.field-document {
  width: 100%;
}

.results {
  margin-top: 20px;
  padding: 10px;
  overflow: auto;
}
div.results {
  display: grid;
  grid-column-gap: 10px;
}
.results .card {
  padding: 8px;
  cursor: pointer;
}
.results tr {
  cursor: pointer;
}
.results .card:hover {
  background: #f8f8f8;
}
.results .selected{
  border: 4px solid #7957d5;
}
.results .selected td:first-child{
  background: #7957d5;
  color: white;
}
.results .card.selected {
  background: #7957d5;
  color: white;
  border: 0;
}
.results .selected td{
  padding-top: 4px;
  padding-bottom: 4px;
}
.card .card-header-icon {
  transition: margin-top 0.2s, margin-bottom 0.2s, font-size 0.2s;
}

.card {
  transition: background 0.2s, box-shadow 0.2s,;
  background: #f9f9f9;
  border: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.card:hover {
  background: #CCCCCC22;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.1)
}

.card:hover .card-header-icon {
  margin-top: -6px;
  margin-bottom: -7px;
  font-size: 24px;
}
</style>
<style>
.complex-field-editor.documents-field {
  margin: 0;
}
.complex-field-editor.documents-field .field-header {
  margin-bottom: 20px;
}
.complex-field-editor.documents-field .content {
  width: calc(100% - 2px);
}
.complex-field-editor.documents-field h3, .complex-field-editor.documents-field h4 {
  margin: 0;
  margin-left: 20px;
}
.complex-field-editor.documents-field .tab-content .tab-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.complex-field-editor.documents-field .tab-content {
  padding: 0;
}
.complex-field-editor.documents-field .modal-card-body {
  overflow: hidden;
  padding: 0;
  height: 85vh;
}
.complex-field-editor.documents-field .search-container {
  padding: 20px;
  padding-bottom: 10px;
  background: whitesmoke;
  border-bottom: 1px solid #dbdbdb;
  display: inline-flex;
  width: 100%;
}
.complex-field-editor.documents-field .search-container .search {
  flex-grow: 1;
}
</style>
