<template>
  <div v-attributes="schema.attributes">
    <v-runtime-template v-if="schema && schema.html !== undefined" :template="schema.html" />
    <b-message v-else has-icon type="is-warning">
      <b>Pas de contenu affichable pour ce champ.</b><br/>
      Pour utiliser ce champ correctement, vous devez compléter l'option "html" dans les options du
      champ.
    </b-message>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import VRuntimeTemplate from 'v-runtime-template';

export default {
  components: {
    VRuntimeTemplate,
  },
  mixins: [abstractField],
};
</script>
