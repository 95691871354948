<template>
  <div v-attributes="schema.attributes">
    <img ref="image" />
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import axios from 'axios';

export default {
  mixins: [abstractField],
  mounted() {
    const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:3332';
    axios({
      url: `${baseUrl}/system_files/${this.schema.image[0]._id}/file`,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Token ${this.$store.state.login.user.token}`,
      },
    }).then((response) => {
      const blob = new Blob([response.data]);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.image.src = e.target.result;
      };
      reader.readAsDataURL(blob);
    });
  },
};
</script>
