import FieldArray from 'vfg-field-array';
import FieldObject from 'vfg-field-object';
//import {FieldWysiwyg} from 'vfg-field-wysiwyg';
import FieldArrayItemContainer from '@/components/vfg/ArrayItemContainers/FieldArrayItemContainer';
import FieldApiendpoint from '@/components/vfg/FieldApiendpoint';
import FieldCode from '@/components/vfg/FieldCode';
import FieldIconboxes from '@/components/vfg/FieldIconboxes';
import FieldFunction from '@/components/vfg/FieldFunction';
import FieldDocumentlock from '@/components/vfg/FieldDocumentlock';
//import FieldHtml from '@/components/vfg/FieldHtml';
//import FieldWysiwyg from '@/components/vfg/FieldWysiwyg';
import FieldImage from '@/components/vfg/FieldImage';
import FieldCustomhtml from '@/components/vfg/FieldCustomhtml';
import FieldCodePortal from '@/components/vfg/FieldCodePortal';
import FieldColor from '@/components/vfg/FieldColor';
import FieldCustomChecklist from '@/components/vfg/FieldCustomChecklist';
import FieldCustomSelect from '@/components/vfg/FieldCustomSelect';
import FieldSimplerelation from '@/components/vfg/FieldSimplerelation';
import FieldDate from '@/components/vfg/FieldDate';
import FieldDateReadOnly from '@/components/vfg/FieldDateReadOnly';
import FieldDocument from '@/components/vfg/FieldDocument';
import FieldDocuments from '@/components/vfg/FieldDocuments';
import FieldPlaceholder from '@/components/vfg/FieldPlaceholder';
import FieldGroup from '@/components/vfg/FieldGroup';
import FieldQrcode from '@/components/vfg/FieldQrcode';
import FieldFiles from '@/components/vfg/FieldFiles';
import FieldIconSelector from '@/components/vfg/FieldIconSelector';
import FieldJson5 from '@/components/vfg/FieldJson5';
import FieldNumber from '@/components/vfg/FieldNumber';
import FieldPermissions from '@/components/vfg/FieldPermissions';
import FieldPropertyvalidator from '@/components/vfg/FieldPropertyvalidator';
import FieldPropertyvisibility from '@/components/vfg/FieldPropertyvisibility';
// import FieldProtectedtext from '@/components/vfg/FieldProtectedtext';
import FieldCategory from '@/components/vfg/FieldCategory';
import FieldCollection from '@/components/vfg/FieldCollection';
import FieldView from '@/components/vfg/FieldView';
import FieldSchemaProperty from '@/components/vfg/FieldSchemaProperty';
import FieldSchemapropertyselector from '@/components/vfg/FieldSchemapropertyselector';
import FieldSchemaproperties from '@/components/vfg/FieldSchemaproperties';
import FieldSchemapropertieseditor from '@/components/vfg/FieldSchemapropertieseditor';
import FieldText from '@/components/vfg/FieldText';
import FieldUndefined from '@/components/vfg/FieldUndefined';
import FieldWeek from '@/components/vfg/FieldWeek';
import FieldSessiontoken from '@/components/vfg/FieldSessiontoken';

export default {
  FieldArray: FieldArray.FieldArray,
  FieldObject: FieldObject.FieldObject,
  FieldArrayItemContainer,
  FieldApiendpoint,
  FieldCode,
  FieldIconboxes,
  FieldCodePortal,
  FieldColor,
  FieldFunction,
  FieldDocumentlock,
//  FieldHtml,
//  FieldWysiwyg,
  FieldImage,
  FieldCustomhtml,
  FieldCustomChecklist,
  FieldCustomSelect,
  FieldSimplerelation,
  FieldDate,
  FieldDateReadOnly,
  FieldDocument,
  FieldDocuments,
  FieldPlaceholder,
  FieldGroup,
  FieldQrcode,
  FieldFiles,
  FieldIconSelector,
  FieldJson5,
  FieldNumber,
  FieldPermissions,
  FieldPropertyvalidator,
  FieldPropertyvisibility,
  // FieldProtectedtext,
  FieldCategory,
  FieldCollection,
  FieldView,
  FieldSchemaProperty,
  FieldSchemapropertyselector,
  FieldSchemaproperties,
  FieldSchemapropertieseditor,
  FieldText,
  FieldUndefined,
  FieldWeek,
  FieldSessiontoken,
};
